export const rewardActivityTypeConfig = {
  POINT: {
    label: '點數',
    unit: '點',
  },
  CASHBACK: {
    label: '回饋金',
    unit: '元',
  },
}
