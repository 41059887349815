import { admin2 } from '@/api/instance'

export const GetRewardActivityDelaySendSetting = async ({
  shopId,
  source,
  rewardType,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/rewardActivityDelayedSendSetting`,
    params: {
      source,
      rewardType,
    },
  })
}
export const UpdateRewardActivityDelaySendSetting = async ({
  shopId,
  source,
  rewardType,
  mode,
  delayDays,
  sendOffsetHours,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/rewardActivityDelayedSendSetting`,
    data: {
      source,
      rewardType,
      mode,
      delayDays,
      sendOffsetHours,
    },
  })
}

//= > 獲取活動列表
export const GetRewardActivity = async ({
  shopId,
  start,
  limit,
  source,
  rewardType,
  name,
  enabled,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/rewardActivity`,
    params: {
      start,
      limit,
      source,
      rewardType,
      name,
      enabled,
    },
  })
}

//= > 獲取單一活動
export const FindRewardActivity = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/rewardActivity/${id}`,
  })
}

export const GetRewardActivityCount = async ({
  shopId,
  source,
  rewardType,
  name,
  enabled,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/rewardActivity/count`,
    params: {
      source,
      rewardType,
      name,
      enabled,
    },
  })
}

export const CreateRewardActivity = async ({
  shopId,
  postBody,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/rewardActivity`,
    data: {
      ...postBody,
    },
  })
}

export const UpdateRewardActivity = async ({
  shopId,
  id,
  postBody,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/rewardActivity/${id}`,
    data: {
      ...postBody,
    },
  })
}

export const DeleteRewardActivity = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'DELETE',
    url: `/${shopId}/rewardActivity/${id}`,
  })
}

//= > 取得銷售產品
export const GetSalesProduct = async ({
  shopId,
  start,
  limit,
  name,
  useClassTicket,
  useCoin,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/salesProduct`,
    params: {
      start,
      limit,
      name,
      useClassTicket,
      useCoin,
    },
  })
}

//= > 取得銷售產品總數
export const GetSalesProductCount = async ({
  shopId,
  name,
  useClassTicket,
  useCoin,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/salesProduct/count`,
    params: {
      name,
      useClassTicket,
      useCoin,
    },
  })
}

export const GetSerialActivityConfig = async ({
  shopId,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/serialActivityConfig`,
  })
}

export const UpdateSerialActivityConfig = async ({
  shopId,
  clientEnabled,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/serialActivityConfig`,
    data: {
      clientEnabled,
    },
  })
}

export const RewardActivityPreCalculateReward = async ({
  shopId,
  source,
  memberId,
  items,
  paidAmount,
}) => {
  return await admin2({
    method: 'post',
    url: `/${shopId}/rewardActivity/preCalculateReward`,
    data: {
      source,
      memberId,
      items,
      paidAmount,
    },
  })
}
