<template>
  <div class="external-transaction-activity-edit">
    <div v-loading="loading" />
    <template v-if="!loading">
      <RewardActivityInfoBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :activityData="activityData"
      />
      <RewardActivityTypeBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :typeSettingConfig="typeSettingConfig"
        :changeTypeHandler="changeTypeHandler"
        :activityData="activityData"
      />
      <RewardActivityRulesBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :rewardType="rewardType"
        :activityData="activityData"
      />
      <RewardActivityLimitBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :rewardType="rewardType"
        :activityData="activityData"
      />
    </template>

    <PageFixedFooter @cancel="onCancel" @confirm="onSubmit" />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import store from '@/store'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import RewardActivityInfoBlock from '@/components/RewardActivity/RewardActivityInfoBlock.vue'
import RewardActivityTypeBlock from '@/components/RewardActivity/RewardActivityTypeBlock.vue'
import RewardActivityRulesBlock from '@/components/RewardActivity/RewardActivityRulesBlock.vue'
import RewardActivityLimitBlock from '@/components/RewardActivity/RewardActivityLimitBlock.vue'
import FormsContext from './formsContext'
import { CreateRewardActivity, FindRewardActivity, UpdateRewardActivity } from '@/api/rewardActivity'
import { usePermissions } from '@/use/permissions'
import { filter } from 'lodash'

export default defineComponent({
  name: 'ExternalTransactionActivityEdit',
  components: {
    PageFixedFooter,
    RewardActivityInfoBlock,
    RewardActivityTypeBlock,
    RewardActivityRulesBlock,
    RewardActivityLimitBlock,
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const shopId = computed(() => store.getters.shop)
    const activityId = computed(() => route.params.id)
    const activityData = ref(null)
    const loading = ref(false)
    const { checkAction } = usePermissions()

    const onCancel = () => {
      router.push({
        name: 'externalTransactionActivity',
      })
    }

    const originTypeSettingConfig = [
      {
        label: '點數',
        value: 'POINT',
        featureKey: 'admin.shopPoint.page',
        // action: checkAction('admin.shopPoint.page'),
      },
      {
        label: '回饋金',
        value: 'CASHBACK',
        featureKey: 'admin.shopCashback.page',
        // action: checkAction('admin.shopCashback.page'),
      },
    ]

    // const typeSettingConfig = filter(originTypeSettingConfig, (data) => {
    //   return data.action
    // })
    const typeSettingConfig = originTypeSettingConfig

    const rewardType = ref(typeSettingConfig[0].value)
    const changeTypeHandler = (data) => {
      rewardType.value = data
    }

    const findActivity = async () => {
      loading.value = true
      const [res, err] = await FindRewardActivity({
        shopId: shopId.value,
        id: activityId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      activityData.value = res
      rewardType.value = res.rewardType
    }

    const createRewardActivity = async (payload) => {
      loading.value = true
      const [, err] = await CreateRewardActivity({
        shopId: shopId.value,
        postBody: {
          ...payload,
        },
      })
      if (err) throw err
      window.$message.success('新增成功!')
    }

    const updateRewardActivity = async (payload) => {
      loading.value = true
      const [, err] = await UpdateRewardActivity({
        shopId: shopId.value,
        id: activityId.value,
        postBody: {
          ...payload,
        },
      })
      if (err) throw err
      window.$message.success('更新商品成功！')
    }

    const onSubmit = async () => {
      const allPass = await FormsContext.validateAll()
      if (allPass) {
        const data = FormsContext.composFormData()
        const postBody = {
          ...data,
          source: 'MEMBER_EXTERNAL_TRANSACTION',
          sourceItemsSettings: [],
          sourceItemsOperator: 'OR',
        }

        try {
          if (!activityId.value) await createRewardActivity(postBody)
          if (activityId.value) await updateRewardActivity(postBody)
          router.push({ name: 'externalTransactionActivity' })
        } catch (error) {
          window.$message.error(error)
          loading.value = false
        }
      }
    }

    onMounted(() => {
      if (activityId.value) findActivity()
    })

    return {
      onCancel,
      onSubmit,
      loading,
      FormsContext,
      typeSettingConfig,
      changeTypeHandler,
      rewardType,
      activityData,
    }
  },
})
</script>

<style lang="postcss" scoped>
.external-transaction-activity-edit {
  @apply flex flex-col gap-[20px];
}
.form-wrapper {
  @apply px-[32px] py-[24px];
}
</style>
